@tailwind base;
@tailwind components;
@tailwind utilities;

@media (max-width: 768px) {
  .component--spacer {
    height: 60px !important;
  }
}

.two-col-cta__container ul {
  @apply list-none text-lg leading-8 md:text-1.5xl md:leading-9 font-normal;
}

.two-col-cta__container p {
  @apply text-3xl leading-supertight font-normal mb-5 md:text-5.5xl md:mb-10;
}

.navlink.active .navlink__underline {
  @apply w-full;
}

.alice-carousel__play-btn {
  @apply opacity-0 -z-2;
}

/* .projects-wrap div:nth-child(1n) {
  @apply basis-[56%];
}
.projects-wrap div:nth-child(2n) {
  @apply basis-[44%] pr-0 pl-8;
}
.projects-wrap div:nth-child(3n) {
  @apply basis-[72%] pr-0;
}
.projects-wrap div:nth-child(4n) {
  @apply basis-[44%] pr-8;
}
.projects-wrap div:nth-child(5n) {
  @apply basis-[56%] pr-0;
} */

.projects-wrap div.size-56-1 {
  @apply basis-[56%];
}
.projects-wrap div.size-44-1 {
  @apply basis-[44%] pr-0 md:pl-8;
}
.projects-wrap div.size-72 {
  @apply basis-[72%] pr-0;
}
.projects-wrap div.size-44-2 {
  @apply basis-[44%] md:pr-8;
}
.projects-wrap div.size-56-2 {
  @apply basis-[56%] pr-0;
}

@media (min-width: 1025px) {
  .animate {
    @apply transition-all transform duration-700;
  }

  .slide-from-top {
    @apply opacity-0 -translate-y-12;
  }
  .slide-from-bottom {
    @apply opacity-0 translate-y-12;
  }
  .slide-from-left {
    @apply opacity-0 -translate-x-12;
  }
  .slide-from-right {
    @apply opacity-0 translate-x-12;
  }
  .fade-in-grow {
    @apply opacity-0 scale-90;
  }
  .fade-in {
    @apply opacity-0;
  }

  .on-screen .slide-from-top {
    @apply opacity-100 translate-y-0;
  }
  .on-screen .slide-from-bottom {
    @apply opacity-100 translate-y-0;
  }
  .on-screen .slide-from-left {
    @apply opacity-100 translate-x-0;
  }
  .on-screen .slide-from-right {
    @apply opacity-100 translate-x-0;
  }
  .on-screen .fade-in-grow {
    @apply opacity-100 scale-100;
  }
  .on-screen .fade-in {
    @apply opacity-100;
  }

  .slide-from-top.on-screen {
    @apply opacity-100 translate-y-0;
  }
  .slide-from-bottom.on-screen {
    @apply opacity-100 translate-y-0;
  }
  .slide-from-left.on-screen {
    @apply opacity-100 translate-x-0;
  }
  .slide-from-right.on-screen {
    @apply opacity-100 translate-x-0;
  }
  .fade-in-grow.on-screen {
    @apply opacity-100 scale-100;
  }
  .fade-in.on-screen {
    @apply opacity-100;
  }
}

html, body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}
.wrapper {
  min-height: 100vh;
  flex: 1 0 auto;
}
.footer {
  flex-shrink: 0;
  background-color: black;
  color: white;
}
